<template>
    <div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="FileName"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-content-width="100%"
            :htmlToPdfOptions="pdfOption"
            ref="html2Pdf">
            
            <section slot="pdf-content">
                <div class="pdf-default">
                    <table border="1" class="mytable mytable-head">
                        <thead>
                            <tr>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Judul</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Grup Akun</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Akun</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Nama Akun</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Saldo Awal</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Saldo</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Kredit</label></td>
                                <td class="px-3" width="10%" style="text-align: center;"><label>Debit</label></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                                <td class="px-2"><label>{{item['head_name']}}</label></td>
                                <td class="px-2"><label>{{item['group_name']}}</label></td>
                                <td class="px-2"><label>{{item['chart_of_account_id']}}</label></td>
                                <td class="px-2"><label>{{item['chart_of_account_name']}}</label></td>
                                <td class="px-2"><label>{{item['initial_balance']}}</label></td>
                                <td class="px-2"><label>{{item['balance']}}</label></td>
                                <td class="px-2"><label>{{item['credit']}}</label></td>
                                <td class="px-2"><label>{{item['debit']}}</label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';
import trialBalanceServices from '../../Areas/Accounting/TrialBalance/Script/TrialBalanceServices';

export default {
    name: 'TrialBalancePDF',
    components: {
        'vue-html2pdf': VueHtml2pdf
    },
    data(){
        return{
            FileName: "Trial_Balance_" + moment(new Date()).format("YYYY-MM-DD-HHmmss"),
            items: [],
            pdfOption: {
                margin: [5,5],
                html2canvas: {
                    scale: 4,
                    useCORS: true
                },
                jsPDF: {
                    unit: 'mm',
                    format: 'a4',
                    orientation: 'portrait'
                },
                pagebreak: {
                    avoid: ['tr', 'css', 'legacy']
                },
            }
        }
    },
    methods: {
        async generatePDF(startDate, endDate, setting) {
            var variables = {
                startDate,
                endDate,
                setting
            };

            var data = await trialBalanceServices.getTrialBalanceQuery(variables);
            var gridData = trialBalanceServices.trialBalanceItemPDF(data);
            this.items = gridData;

            this.$refs.html2Pdf.generatePdf();
        },
    }
}
</script>

<style scoped>
.col-sm-dot, .col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 30%;
    }
    .col-sm-dot {
        width: 5%;
    }
    .col-sm-content {
        width: 60%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}

.mytable {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
}
.mytable-head {
    border: 1px solid white;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-head td {
    border: 1px solid black;
}
.mytable-body {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.mytable-body td {
    border: 1px solid black;
    border-top: 0;
}
.mytable-footer {
    border: 1px solid black;
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
}
.mytable-footer td {
    border: 1px solid black;
    border-top: 0;
}
</style>